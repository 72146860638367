import Advertisement from '../Advertisement'
import HomepageVideoModule from './HomepageVideoModule'
import React from 'react'
import ReactDOM from 'react-dom'
import { nt } from 'helpers/i18n'
const i18n = nt('home.index')

export default class HomepageTopVideosModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articles: []
    }
  }

  async componentDidMount() {
    const resp = await fetch('/api/v1/homepage/top_videos')
    if (resp.status === 200) {
      const json = await resp.json()
      this.setState({ articles: json.cards })
    }
  }

  componentDidUpdate() {
    if (this.state.articles.length > 0) {
      imgix.fluid(ReactDOM.findDOMNode(this))
    }
  }

  render() {
    if (!this.state.articles.length) return null
    let { ad_targeting, onAdReady, type } = this.props

    return (
      <div>
        <Advertisement
          onAdReady={onAdReady}
          parent_component={type}
          customTargeting={ad_targeting}
          type="homepageBillboardBreakerAd"
          ad_id="billboard"
        />

        <div className="homepage-module homepage-module--top-videos">
          <div className="content-wrapper">
            <a href="/videos" className="view-all">
              {i18n('view_all_videos')}
            </a>
            <p className="title">{i18n('most_watched_videos')}</p>
            <HomepageVideoModule articles={this.state.articles} />
            <a href="/videos" className="btn">
              {i18n('view_all_videos')}
            </a>
          </div>
        </div>
      </div>
    )
  }
}
