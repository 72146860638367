import React from 'react'
import HomepageArticleModule from './HomepageArticleModule'
import i18n from 'helpers/i18n'

export default class HomepageShopModule extends React.Component {
  render() {
    return (
      <HomepageArticleModule isShop={true} buttonText={i18n('home.index.shop_now')} {...this.props}>
        <svg className="icon shop-logo inline">
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-hodinkee-shop-light" />
        </svg>
      </HomepageArticleModule>
    )
  }
}
