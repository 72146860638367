import Authors from './Authors'
import ImgixHelper from 'helpers/ImgixHelper'
import React from 'react'
import ReactDOM from 'react-dom'
import i18n from 'helpers/i18n'
import ArticleBadge from './ArticleBadge'

export default class HomepageTopStoriesModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articles: []
    }
  }

  async componentDidMount() {
    const resp = await fetch('/api/v1/cards?sort=top_this_week&limit=5')
    if (resp.status === 200) {
      const json = await resp.json()
      this.setState({ articles: json.cards.filter(card => card.type == 'article') })
    }
  }

  componentDidUpdate() {
    if (this.state.articles.length > 0) {
      imgix.fluid(ReactDOM.findDOMNode(this))
    }
  }

  getHeroArticle() {
    let article = this.state.articles[0]
    const ix = new ImgixHelper(article.media.image.url)

    return (
      <div className="article article--hero">
        <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
          <a href={article.action.share_url} className="article-hero-image__link" />
        </div>
        <div className="article-info">
          <h3 className="article-title">
            <a href={article.action.share_url} className="article-link">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </a>
          </h3>
          <p className="article-meta">
            <Authors authors={article.authors} />
          </p>
        </div>
      </div>
    )
  }

  getArticles() {
    return this.state.articles.slice(1).map((article, i) => {
      const ix = new ImgixHelper(article.media.image.url)
      return (
        <div className="article" key={i}>
          <div className="article-hero-outer">
            <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
              <ArticleBadge article={article} className="article__badge" />
              <a href={article.action.share_url} className="article-hero-image__link" />
            </div>
          </div>
          <div className="article-info">
            <h3 className="article-title">
              <a href={article.action.share_url} className="article-link">
                <span>{article.metadata.column_name}</span>
                {article.message.title}
              </a>
            </h3>
            <p className="article-meta">
              <Authors authors={article.authors} />
            </p>
          </div>
        </div>
      )
    })
  }

  render() {
    if (!this.state.articles.length) return null
    let { onAdReady } = this.props

    return (
      <div className="homepage-module homepage-module--top-stories">
        <div className="content-wrapper small-fullbleed">
          <h2>
            <span>{i18n('home.index.top_stories')}</span>
          </h2>
          {this.getHeroArticle()}
          <div className="articles-grid">{this.getArticles()}</div>
        </div>
      </div>
    )
  }
}
