import React from 'react'
import i18n from 'helpers/i18n'

export default class Authors extends React.PureComponent {
  getAuthors() {
    const authors = this.props.authors.map(author => {
      if (author.member_username) {
        return (
          <a key={author.id} href={`/members/${author.member_username}`}>
            {author.name}
          </a>
        )
      } else {
        return <span key={author.id}>{author.name}</span>
      }
    })

    if (authors && authors.length) {
      return authors.reduce((accu, elem) => [accu, ', ', elem])
    } else {
      return null
    }
  }

  render() {
    const authors = this.getAuthors()
    if (!authors) return null

    return (
      <span>
        <span className="by">{i18n('home.index.by_authors')}</span> {authors}
      </span>
    )
  }
}
