import React from 'react'
import ReactDOM from 'react-dom'
import Authors from './Authors'
import ImgixHelper from 'helpers/ImgixHelper'

/**
 * Handles Collections, Columns, and Packages
 */
export default class HomepageFlexModule extends React.Component {
  componentDidMount() {
    imgix.fluid(ReactDOM.findDOMNode(this))
  }

  componentDidUpdate() {
    ReactDOM.findDOMNode(this)
      .querySelectorAll('.imgix-fluid')
      .forEach(fluidElement => {
        fluidElement.url = new imgix.URL(fluidElement.getAttribute('data-src'))
        imgix.fluid(ReactDOM.findDOMNode(this), { updateOnResizeDown: true }).updateSrc(fluidElement)
      })
  }

  getHeroArticle() {
    const article = this.props.articles[0]
    const ix = new ImgixHelper(article.media.image.url)

    return (
      <div className={`article article--hero align-${this.props.alignment}`}>
        <div className="article-hero-container">
          <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()} />
          <a href={article.action.share_url} className="article-hero-image__link" />
        </div>
        <div className="article-info-container">
          <h3 className="article-title leading-tight">
            <a href={article.action.share_url} className="article-link">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </a>
          </h3>
          <h4 className="article-lede" dangerouslySetInnerHTML={{ __html: article.message.text_html }} />
          <p className="article-meta">
            <Authors authors={article.authors} />
          </p>
        </div>
      </div>
    )
  }

  getArticles() {
    return this.props.articles.slice(1).map(function(article, i) {
      const ix = new ImgixHelper(article.media.image.url)
      return (
        <div className="article" key={i}>
          <a href={article.action.share_url}>
            <div className="article-hero-container">
              <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()} />
            </div>
            <h3 className="article-title leading-tight">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </h3>
          </a>
          <p className="article-meta hide-until-mobile">
            <Authors authors={article.authors} />
          </p>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        {this.getHeroArticle()}
        <div className="articles-grid">{this.getArticles()}</div>
      </div>
    )
  }
}
