import throttle from 'lodash/throttle'
import { domReady, FOCUSABLE_SELECTORS } from 'site/helpers'
import 'site/helpers/dropdownMenu'

domReady(() => {
  const navigation = document.querySelector('#navigation')

  // Everything here is scoped to the navigation element. If it's not present,
  // we can bail.
  if (!navigation) return

  // Dropdown menu implementation

  navigation.querySelectorAll('.toggle-nav-submenu').forEach(btn => {
    const submenu = document.querySelector(btn.dataset.submenu)
    if (submenu === null) return

    const handleNextKeypress = e => {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          setMenuOpen(false)
        } else {
          e.preventDefault()
          submenu.querySelector(FOCUSABLE_SELECTORS).focus()
          navigation.removeEventListener('keydown', handleNextKeypress)
        }
      }
    }

    // State handling for the current open state of the submenu
    let menuOpen = false
    const setMenuOpen = open => {
      menuOpen = open
      btn.setAttribute('aria-expanded', open)

      if (open) {
        submenu.classList.add('submenu-open')
        navigation.addEventListener('keydown', handleNextKeypress)
      } else {
        submenu.classList.remove('submenu-open')
        navigation.removeEventListener('keydown', handleNextKeypress)
      }
    }

    // Event listener that will close the submenu if focus leaves it.
    // This will automatically focus the next sibling in the navbar to ensure
    // proper tab order. It tracks the last focused element in order to know which
    // direction you're tabbing.
    let lastFocusedElement
    document.addEventListener(
      'focus',
      e => {
        if (!menuOpen) return
        if (!submenu.contains(e.target) && !btn.contains(e.target)) {
          e.preventDefault()

          const focusableItems = submenu.querySelectorAll(FOCUSABLE_SELECTORS)

          const nextFocus =
            lastFocusedElement === focusableItems[0] ? btn.parentElement : btn.parentElement.nextElementSibling

          setMenuOpen(false)

          nextFocus?.querySelector(FOCUSABLE_SELECTORS)?.focus()
        }

        lastFocusedElement = e.target
      },
      true
    )

    // Watches for clicks outside of the menu to close it
    document.addEventListener('click', e => {
      if (menuOpen && !btn.contains(e.target) && !submenu.contains(e.target)) {
        setMenuOpen(false)
      }
    })

    // Click handler that toggles the menu, also works for the Enter key
    btn.addEventListener('click', e => {
      setMenuOpen(!menuOpen)
    })
  })

  // Search implementation
  const searchInputContainer = navigation.querySelector('.search-input-container')
  const searchInput = searchInputContainer.querySelector('.search-input')
  const searchFocusLost = e => {
    if (!searchInputContainer.contains(e.target)) {
      console.log('search toggle')
      toggleSearchInput()
    }
  }

  let searchOpen = false
  const toggleSearchInput = () => {
    if (searchOpen) {
      searchInputContainer.classList.remove('is-active')
      searchOpen = false
      document.removeEventListener('focus', searchFocusLost, true)
    } else {
      searchInputContainer.classList.add('is-active')
      searchInput.focus()
      searchOpen = true
      document.addEventListener('focus', searchFocusLost, true)
    }
  }

  navigation.querySelectorAll('.search-toggle').forEach(searchToggle => {
    searchToggle.addEventListener('click', e => {
      toggleSearchInput()
    })
  })

  // Hamburger toggle
  const burger = navigation.querySelector('.hamburger')
  const mobileMenu = navigation.querySelector('#mobile-menu')
  let mobileMenuOpen = false

  const adjustMobileMenuHeight = () => {
    const height = window.innerHeight - mobileMenu.getBoundingClientRect().top
    mobileMenu.style.height = `${height}px`

    if (window.innerWidth >= 1024) {
      setMobileMenuOpen(false)
    }
  }

  const setMobileMenuOpen = open => {
    if (open) {
      burger.classList.add('is-active')
      mobileMenu.classList.add('is-active')
      document.body.classList.add('overflow-hidden')
      adjustMobileMenuHeight()

      window.addEventListener('resize', adjustMobileMenuHeight)
    } else {
      burger.classList.remove('is-active')
      mobileMenu.classList.remove('is-active')
      document.body.classList.remove('overflow-hidden')
      window.removeEventListener('resize', adjustMobileMenuHeight)
    }

    mobileMenuOpen = open
  }

  burger.addEventListener('click', e => {
    setMobileMenuOpen(!mobileMenuOpen)
  })

  // Mobile menu expandable sections
  mobileMenu.querySelectorAll('.expandable-toggle').forEach(toggle => {
    toggle.addEventListener('click', () => {
      toggle.nextElementSibling?.classList?.toggle('is-active')
      if (toggle.getAttribute('aria-expanded') === 'false') {
        toggle.setAttribute('aria-expanded', 'true')
      } else {
        toggle.setAttribute('aria-expanded', 'false')
      }
    })
  })

  const mobileSearch = mobileMenu.querySelector('.search')
  const mobileQuicklinks = mobileSearch.querySelector('.quicklinks')
  mobileSearch.addEventListener(
    'focus',
    e => {
      mobileQuicklinks.classList.remove('hidden')
    },
    true
  )

  // Sticky nav implementation
  const navLinks = navigation.querySelector('#sticky-nav')
  if (navLinks) {
    // Insert an empty anchor so we know the original location of the nav at all times
    const anchor = document.createElement('div')
    navLinks.parentElement.insertBefore(anchor, navLinks)

    let navFixed = false
    const setNavFixed = fixed => {
      if (fixed && !navFixed) {
        navFixed = true
        navLinks.classList.add('is-sticky')
        anchor.style.height = `${navLinks.offsetHeight}px`
      } else if (!fixed && navFixed) {
        navFixed = false
        navLinks.classList.remove('is-sticky')
        anchor.style.height = '0px'
      }
    }

    const checkStickyNav = () => {
      if (anchor.getBoundingClientRect().top <= 0) {
        setNavFixed(true)
      } else {
        setNavFixed(false)
      }
    }

    window.addEventListener('scroll', throttle(checkStickyNav, 20))
  }
})
