export default {
  homepageHeaderBillboardAd: {
    type: 'homepageHeaderBillboardAd',
    google_id: `/${process.env.GOOGLE_AD_MANAGER_ID}/hmijp-hodinkee`,
    sizes: [[970, 251], [970, 250]],
    mapping: 'billboardAdLargeScreensMapping'
  },
  homepageBillboardBreakerAd: {
    type: 'homepageBillboardBreakerAd',
    google_id: `/${process.env.GOOGLE_AD_MANAGER_ID}/hmijp-hodinkee`,
    sizes: [[970, 500], [970, 250], [300, 250]],
    mapping: 'billboardBreakerAdMapping'
  },
  homepageBillboardAd: {
    type: 'homepageBillboardAd',
    google_id: `/${process.env.GOOGLE_AD_MANAGER_ID}/hmijp-hodinkee`,
    sizes: [[970, 250], [300, 250]],
    mapping: 'billboardAdMapping'
  },
  homepageSquareAd: {
    type: 'homepageSquareAd',
    google_id: `/${process.env.GOOGLE_AD_MANAGER_ID}/hmijp-hodinkee`,
    sizes: [[300, 250]],
    mapping: 'homepageSquareAd'
  },
  homepageTallAd: {
    type: 'homepageTallAd',
    google_id: `/${process.env.GOOGLE_AD_MANAGER_ID}/hmijp-hodinkee`,
    sizes: [[300, 250], [300, 600]],
    mapping: 'tallAdMapping'
  },
  packageBreakerAd: {
    type: 'packageBreakerAd',
    google_id: `/${process.env.GOOGLE_AD_MANAGER_ID}/hmijp-hodinkee`,
    sizes: [[300, 250], [300, 600]],
    mapping: 'packageBreakerAd'
  }
}
