import imgix from 'imgix.js'

import AdManager from './dfpManager'
import SegmentHelpers from './helpers/segment'
import './modules/SiteHeader'

import initializePageController from './controllers'

if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
  initialize()
} else {
  document.addEventListener('DOMContentLoaded', initialize, false)
}

function initialize() {
  initializePageController()

  var adManager = AdManager.getAdManager()

  // Init adManager, and fetch ads
  adManager.init()

  // Export the Segment helpers to global functions
  SegmentHelpers.init()
}

imgix.onready(() => {
  imgix.fluid({
    updateOnResize: true,
    updateOnResizeDown: true,
    pixelStep: 5
  })
})
