import React, { useEffect, useRef } from 'react'
import ImgixHelper from 'helpers/ImgixHelper'

export default function HomepageHeroPackage({ pack }) {
  const ix = new ImgixHelper(pack.media.image.url)

  const moduleEl = useRef(null)
  useEffect(() => {
    imgix.fluid(moduleEl.current)
  })

  return (
    <div className="article" ref={moduleEl}>
      <a href={pack.action.share_url}>
        <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}></div>
        <div className="article-info">
          <h3 className="article-title">{pack.metadata.editorial_title || pack.message.title}</h3>
        </div>
      </a>
    </div>
  )
}
