import capitalize from 'lodash/capitalize'
import snakeCase from 'lodash/snakeCase'

/*
 * Add all Segment event helpers to this file. You can use the helpers in the markup and
 * React from any place within `site`.
 *
 * The helpers should be reusable in order to not fill the `init` function with
 * case-specific helpers.
 *
 */

var SegmentHelpers = (function (W) {
  // Use `trait(object, key, value)` instead of `object[key] = value` to avoid violations
  // on Segment due to unexpected null/empty values.
  const trait = (o, p, a) => (a && (o[snakeCase(p)] = a), o)

  // This function converts a name like 'shop-header' into a friendly capitalized name
  // like 'Shop Header'. This way anybody can add a 'data' property with the event name
  // that matches the schema name.
  const prettyName = e =>
    e
      .split('-')
      .reduce((e, t) => (e += `${capitalize(t)} `), '')
      .trim()

  // Computes the current screen size of the device in a standarized string format.
  const screenString = () => `${screen.width * devicePixelRatio}x${screen.height * devicePixelRatio}`

  // Extract the traits from the DOM and normalize their name. This will extract the
  // 'data-segment' and normalizes it to a snake case trait name. For example, a
  // 'data-segment-header-type' will become a trait 'header_type'.
  // The first parameter defines an attribute that will be ignored from the list. This
  // is, generally, the attribute where the name of the event comes from. It can be null
  // to ignore none.
  const extractTraits = (attribute, schema, target) => {
    const prefix = 'data-segment-'
    const attr = target.attributes
    for (let i = 0; i < attr.length; i++) {
      const item = attr.item(i)
      if (item.name.startsWith(prefix) && item.name !== attribute) {
        const snake = snakeCase(item.name.replace(prefix, ''))
        schema = trait(schema, snake, item.value)
      }
    }
    return trait(schema, 'screen_resolution', screenString())
  }

  // When adding a listener to the document, use the name as it shows in the schema. For
  // example, for a 'Shop Header Link Clicked' schema event use a
  // 'data-segment-link-clicked' with a value 'shop-header' in the DOM properties.
  function addListeners() {
    document.addEventListener(
      'click',
      ({ target }) => {
        target.hasAttribute('data-segment-link-clicked') && segmentedLinkClicked(target)
      },
      true
    )
  }

  const isDefined = () => {
    return typeof analytics !== 'undefined'
  }

  // To use those functions in React, export them to the global object "segmentEvents"
  // and add/remove them as needed from the React handlers.
  function init() {
    W.segmentEvents = {
      segmentedLinkClicked,
      segmentedPage
    }

    addListeners()
    segmentedPage()
  }

  const segmentedPage = ({ category, name, traits } = {}) => {
    let schema = trait(traits || {}, 'screen_resolution', screenString())
    isDefined() && analytics.page(category, name, schema)
  }

  const segmentedLinkClicked = target => {
    const attribute = 'data-segment-link-clicked'
    const name = prettyName(target.getAttribute(attribute))
    let schema = trait({}, 'href', target.getAttribute('href'))
    schema = extractTraits(attribute, schema, target)
    schema = trait(schema, 'inner_text', target.innerText)
    isDefined() && analytics.track(`${name} Link Clicked`, schema)
  }

  return { init }
})(window)

export default SegmentHelpers
