import React from 'react'

import HomepageFlexModule from './HomepageFlexModule'

export default class HomepageCollectionModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      collection: {}
    }
  }

  componentWillMount() {
    this.fetchCollection()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.collection_id != this.props.collection_id) {
      this.fetchCollection()
    }
  }

  async fetchCollection() {
    if (!this.props.collection_id) return

    const resp = await fetch(`/api/v1/collections/${this.props.collection_id}`)
    if (resp.status === 200) {
      const json = await resp.json()
      this.setState({ collection: json.collection })
    }
  }

  articles() {
    // Collections always return all of the articles that belong to it, no pagination.
    // Since the homepage only shows the first 4 articles, we handle that here.
    return (this.state.collection.cards || []).slice(0, 4)
  }

  render() {
    if (!this.articles().length) return null
    const { collection } = this.state

    return (
      <div className="homepage-module homepage-module--flex">
        <div className="content-wrapper mobile-fullbleed">
          <h2>
            <span>{collection.title}</span>
          </h2>
          <HomepageFlexModule type="collection" {...this.props} articles={this.articles()} />
        </div>
      </div>
    )
  }
}
