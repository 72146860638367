import $ from 'jquery'
import React from 'react'
import {
  HomepageHeroModule,
  HomepageH10Module,
  HomepageNewsletterModule,
  HomepageShopModule,
  HomepageLatestModule,
  HomepageArticleModule,
  HomepageCollectionModule,
  HomepageColumnModule,
  HomepagePackageModule,
  HomepageTopStoriesModule,
  HomepageFeaturedVideosModule,
  HomepageTopVideosModule,
  HomepageTopDiscussionsModule,
  HomepageEditorsPicksModule,
  HomepageOurEditorsModule,
  HomepagePopularBrandsModule,
  HomepageMarketingModules,
  HomepageLastWeekTopStoriesModule
} from './homepage_modules'

import Advertisement from './Advertisement'
import AdContainer from './AdContainer'
import ErrorBoundary from './ErrorBoundary'

export default class Homepage extends React.Component {
  static get modules() {
    return {
      'hero': HomepageHeroModule,
      'breaker.newsletter': HomepageNewsletterModule,
      'breaker.h10': HomepageH10Module,
      'breaker.article': HomepageArticleModule,
      'breaker.shop': HomepageShopModule,
      latest: HomepageLatestModule,
      'flex.collection': HomepageCollectionModule,
      'flex.column': HomepageColumnModule,
      'flex.package': HomepagePackageModule,
      'flex.top_stories': HomepageTopStoriesModule,
      'video.featured_videos': HomepageFeaturedVideosModule,
      'video.top_videos': HomepageTopVideosModule,
      'list.top_discussions': HomepageTopDiscussionsModule,
      'list.last_week_top_stories': HomepageLastWeekTopStoriesModule,
      editors_picks: HomepageEditorsPicksModule,
      our_editors: HomepageOurEditorsModule,
      popular_brands: HomepagePopularBrandsModule,
      marketing: HomepageMarketingModules
    }
  }

  static module(data) {
    let type = data.type
    if (data.subtype) type += `.${data.subtype}`

    return Homepage.modules[type]
  }

  adMappings = [
    {
      name: 'billboardAdMapping',
      fn: function (googletag) {
        return googletag
          .sizeMapping()
          .addSize([0, 0], [300, 250]) // mobile
          .addSize([1024, 200], [970, 250]) // 1024px and above
      }
    },
    {
      name: 'billboardBreakerAdMapping',
      fn: function (googletag) {
        return googletag
          .sizeMapping()
          .addSize([0, 0], [300, 250]) // mobile
          .addSize(
            [1024, 200],
            [
              [970, 500],
              [970, 250]
            ]
          ) // 1024px and above
      }
    },
    {
      name: 'billboardAdLargeScreensMapping',
      fn: function (googletag) {
        return googletag
          .sizeMapping()
          .addSize([0, 0], []) // mobile
          .addSize(
            [1024, 200],
            [
              [970, 251],
              [970, 250]
            ]
          ) // tablet and above
      }
    },
    {
      name: 'homepageSquareAd',
      fn: function (googletag) {
        return googletag
          .sizeMapping()
          .addSize([0, 0], [300, 250]) // up to 768px
          .addSize([769, 200], []) // 769px and above
      }
    },
    {
      name: 'headerAdMapping',
      fn: function (googletag) {
        return googletag
          .sizeMapping()
          .addSize([0, 0], [320, 50]) // mobile and tablet
          .addSize([768, 200], [728, 90]) // tablet and above
      }
    },
    {
      name: 'headerAdSansMobileMapping',
      fn: function (googletag) {
        return googletag
          .sizeMapping()
          .addSize([0, 0], []) // mobile
          .addSize([768, 200], [728, 90]) // tablet and above
      }
    },
    {
      name: 'tallAdMapping',
      fn: function (googletag) {
        return googletag
          .sizeMapping()
          .addSize([768, 200], [300, 600]) // tablet and above
          .addSize([0, 0], []) // mobile
      }
    },
    {
      name: 'videoAdMapping',
      fn: function (googletag) {
        return googletag.sizeMapping().addSize([0, 0], [300, 250])
      }
    }
  ]

  headerBillboardHeight = 0

  constructor(props) {
    super(props)

    this.state = {
      config: this.props.config
    }
    this.homepageRef = React.createRef()
  }

  previewHandler(e) {
    // store the value of the subscribe form plain html before this is
    // erased by the component redraw.
    const $klaviyo = $('[class^="form--newsletter klaviyo"]').html()
    this.setState({ config: e.detail, klaviyo: $klaviyo })
  }

  componentWillUnmount() {
    if (this.props.preview) {
      window.removeEventListener('homepage:configUpdate', this.previewHandler.bind(this), false)
    }
  }

  componentDidMount() {
    $(this.homepageRef.current).on('click', '.article-hero-image__link, .article-link', e => {
      let module = $(e.target).closest('.homepage-module')[0]
      let moduleName = module.className.split('--')[1]
      if (moduleName === 'flex') {
        let heading = $(module).find('h2').text().replace(/\s/g, '-').toLowerCase()
        moduleName += `--${heading}`
      }
      window.dataLayer.push({
        event: 'heroModuleClick',
        moduleName: moduleName
      });
    })

    if (this.props.preview) {
      window.addEventListener('homepage:configUpdate', this.previewHandler.bind(this), false)
    }
  }

  /**
   * When the page is in preview mode and the componet is redraw, inject the plain
   * html of klaviyo subscribe form.
   */
  componentDidUpdate() {
    if (this.props.preview && this.state.klaviyo) {
      $('[class^="form--newsletter klaviyo"]').html(this.state.klaviyo)
    }
  }

  onWindowResize = (prevWindowSize, windowSize) => {
    if (windowSize === 'small' && prevWindowSize === 'large') {
      $('.app-wrapper').css('paddingTop', 0)
    } else if (this.windowSize === 'large' && prevWindowSize === 'small') {
      $('.app-wrapper').css('paddingTop', this.headerBillboardHeight)
    }
  }

  onSlotRenderEnded = event => {
    if (!event.isEmpty && event.slot.getSlotElementId().match('homepage-header-billboard')) {
      this.headerBillboardHeight = document.getElementById(event.slot.getSlotElementId()).clientHeight
      $('.app-wrapper').css('paddingTop', this.headerBillboardHeight)
      $(document).trigger('HomepageHeaderAdLoaded')
    }
  }

  getModules(onAdReady) {
    return this.state.config.map((module, i) => {
      if (module.subtype && module.subtype === 'instagram') return

      let ModuleType = Homepage.module(module)
      if (!ModuleType) return // Prevents homepage from breaking if we add a new module
      return (
        <ErrorBoundary key={`${module.type}-${i}`}>
          <ModuleType
            {...module.data}
            type={module.type}
            onAdReady={onAdReady}
            ad_targeting={this.props.ad_targeting}
          />
        </ErrorBoundary>
      )
    })
  }

  render() {
    return (
      <AdContainer
        adMappings={this.adMappings}
        onSlotRenderEnded={this.onSlotRenderEnded}
        onWindowResize={this.onWindowResize}
        ref={this.homepageRef}
        render={onAdReady => {
          return (
            <React.Fragment>
              <div className="homepage-billboard hide-until-desktopNarrow">
                <Advertisement
                  type="homepageHeaderBillboardAd"
                  ad_id="homepage-header-billboard"
                  customTargeting={this.props.ad_targeting}
                  onAdReady={onAdReady}
                />
              </div>
              <div className="homepage-modules" id="maincontent" tabIndex="-1">
                {this.getModules(onAdReady)}
              </div>
            </React.Fragment>
          )
        }}
      />
    )
  }
}
