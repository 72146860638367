import Authors from './Authors'
import ImgixHelper from 'helpers/ImgixHelper'
import React from 'react'
import ReactDOM from 'react-dom'
import i18n from 'helpers/i18n'

export default class HomepagePopularBrand extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articles: []
    }
  }

  async componentDidMount() {
    try {
      const resp = await fetch(`/api/v1/brands/${this.props.brand.id}/cards?limit=3`)
      if (resp.status === 200) {
        const json = await resp.json()
        this.setState({ articles: json.cards })
      }
    } catch (e) {
      // TODO - Handle empty state
    }
  }

  componentDidUpdate() {
    imgix.fluid(ReactDOM.findDOMNode(this))
  }

  getArticles() {
    return this.state.articles.map(function(article, i) {
      let is_hero = i === 0
      let classes = ['article']
      if (is_hero) {
        classes.push('article--hero')
      }

      return (
        <div className={classes.join(' ')} key={i}>
          <a href={article.action.share_url}>
            {is_hero && (
              <div
                className="article-hero-image imgix-fluid imgix-fluid-bg"
                data-src={new ImgixHelper(article.media.image.url).getURL()}
              ></div>
            )}
            <h3 className="article-title">
              {article.metadata.column_name ? <span>{article.metadata.column_name}</span> : undefined}
              {article.message.title}
            </h3>
          </a>
          {is_hero && (
            <div className="article-meta">
              <Authors authors={article.authors} />
            </div>
          )}
        </div>
      )
    })
  }

  render() {
    const ix = new ImgixHelper(this.props.brand.image)
    return (
      <div className="popular-brand">
        <div className="popular-brand--badge hide-from-desktop imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
          <div className="mask" />
          <span>{this.props.brand.title}</span>
          <a href={`/brands/${this.props.brand.slug}`} className="brand-page-link" />
        </div>
        <div className="hide-until-desktop">
          <a className="popular-brand--view-all" href={`/brands/${this.props.brand.slug}`}>
            {i18n('home.index.view_all')}
          </a>
          <p className="popular-brand--name">{this.props.brand.title}</p>
          <div className="popular-brand--articles">{this.getArticles()}</div>
        </div>
      </div>
    )
  }
}
