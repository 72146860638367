import React from 'react'
import HomepageFlexModule from './HomepageFlexModule'
import i18n from 'helpers/i18n'

export default class HomepageColumnModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      column: null,
      articles: []
    }
  }

  componentWillMount() {
    this.fetchColumn()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.column_id != this.props.column_id) {
      this.fetchColumn()
    }
  }

  async fetchColumn() {
    if (!this.props.column_id) return

    const resp = await fetch(`/api/v1/homepage/column/${this.props.column_id}?limit=4`)
    if (resp.status === 200) {
      const json = await resp.json()
      this.setState(json)
    }
  }

  render() {
    const { column, articles } = this.state
    if (!articles.length || !column) return null

    return (
      <div className="homepage-module homepage-module--flex">
        <div className="content-wrapper mobile-fullbleed">
          <h2>
            <span>{column.name}</span>
          </h2>
          <HomepageFlexModule type="column" {...this.props} articles={articles} />
          <a href={`/browse/${column.id.replace(/_/g, '-')}`} className="btn">
            {i18n('home.index.view_all')}
          </a>
        </div>
      </div>
    )
  }
}
