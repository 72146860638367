import React from 'react'

import ImgixHelper from 'helpers/ImgixHelper'
import Authors from './Authors'

export default class HomepageOurEditorsAuthor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      article: {}
    }
  }

  async componentDidMount() {
    const resp = await fetch(`/api/v1/cards?author=${this.props.author.id}&limit=1`)
    if (resp.status === 200) {
      const data = await resp.json()
      this.setState({ article: data.cards[0] })
    }
  }

  imgixUrl() {
    let ix = new ImgixHelper(this.props.author.avatar_url, { w: 80, h: 80 })
    return ix.getURL()
  }

  render() {
    if (!this.state.article.id) return null
    let article = this.state.article

    return (
      <div className="author">
        <div className="author--avatar">
          <img src={this.imgixUrl()} />
        </div>
        <div className="author--info">
          <p className="author--name">
            <Authors authors={[this.props.author]} />
          </p>
          <h3 className="author--article-title article-title">
            <a href={article.action.share_url} className="article-link">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </a>
          </h3>
        </div>
      </div>
    )
  }
}
