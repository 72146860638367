export function displayAds(ads, onSlotRenderEnded = function() {}) {
  googletag.cmd.push(() => {
    let ids = Object.keys(ads).map(key => {
      return ads[key]
    })

    googletag.pubads().enableSingleRequest()
    googletag.pubads().disableInitialLoad()
    googletag.enableServices()

    Object.keys(ads).forEach(function(id) {
      googletag.display(id)
    })

    googletag.pubads().refresh(ids)
    googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded)
  })
}

export function refreshAds() {
  googletag.cmd.push(() => {
    googletag.pubads().refresh()
  })
}
