import React from 'react'
import ReactDOM from 'react-dom'
import Advertisement from '../Advertisement'
import HomepageVideoModule from './HomepageVideoModule'
import ImgixHelper from 'helpers/ImgixHelper'

import { nt } from 'helpers/i18n'
const i18n = nt('home.index')

export default class HomepageFeaturedVideosModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articles: []
    }
  }

  componentDidMount() {
    this.fetchArticles()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.article_ids != this.props.article_ids) {
      this.fetchArticles()
    } else {
      if (this.state.articles.length > 0) {
        ReactDOM.findDOMNode(this)
          .querySelectorAll('.imgix-fluid')
          .forEach(fluidElement => {
            fluidElement.url = new imgix.URL(fluidElement.getAttribute('data-src'))
            imgix.fluid(ReactDOM.findDOMNode(this), { updateOnResizeDown: true }).updateSrc(fluidElement)
          })
      }
    }
  }

  async fetchArticles() {
    if (!this.props.article_ids || this.props.article_ids.length === 0) return
    const params = this.props.article_ids.map(id => `ids[]=${id}`).join('&')

    const resp = await fetch(`/api/v1/homepage/featured_videos?${params}`)
    if (resp.status === 200) {
      const json = await resp.json()
      this.setState({ articles: json.cards })
    }
  }

  render() {
    if (!this.state.articles.length) return null
    let { ad_targeting, onAdReady, type } = this.props

    return (
      <div>
        <Advertisement
          onAdReady={onAdReady}
          parent_component={type}
          customTargeting={ad_targeting}
          type="homepageBillboardBreakerAd"
          ad_id="billboard"
        />

        <div className="homepage-module homepage-module--top-videos">
          <div className="content-wrapper">
            <a href="/videos" className="view-all">
              {i18n('view_all_videos')}
            </a>
            <p className="title">{i18n('featured_videos')}</p>
            <HomepageVideoModule articles={this.state.articles} />
            <a href="/videos" className="btn">
              {i18n('view_all_videos')}
            </a>
          </div>
        </div>
      </div>
    )
  }
}
