import React from 'react'
import { nt } from 'helpers/i18n'
const i18n = nt('home.index.marketing')
const ariaI18n = nt('home.accessibility')

export default function HomepageAppDownloadModule({ link }) {
  const ariaLabel = `${i18n('get_the_app')}, ${ariaI18n('new_window')}`

  return (
    <div className="marketing-module">
      <div className="marketing-module__ios-teaser-image" />
      <h3>{i18n('get_the_app')}</h3>
      <p>{i18n('app_desc')}</p>
      <a className="cta-text" href={link} target="_blank" aria-label={ariaLabel}>
        {i18n('download_now')}
      </a>
    </div>
  )
}
