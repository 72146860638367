import React from 'react'
import HomepageOurEditorsAuthor from './HomepageOurEditorsAuthor'
import i18n from 'helpers/i18n'

export default class HomepageOurEditorsModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      authors: []
    }
  }

  async componentDidMount() {
    const resp = await fetch('/api/v1/homepage/our_editors')
    if (resp.status === 200) {
      const json = await resp.json()
      this.setState({ authors: json.authors })
    }
  }

  getAuthors() {
    return this.state.authors.map(author => {
      return <HomepageOurEditorsAuthor key={author.id} author={author} />
    })
  }

  render() {
    return (
      <div className="homepage-module homepage-module--our-editors">
        <div className="content-wrapper">
          <h2>
            <span>{i18n('home.index.our_editors')}</span>
          </h2>

          <div className="authors">{this.getAuthors()}</div>
        </div>
      </div>
    )
  }
}
