import ImgixHelper from 'helpers/ImgixHelper'
import React from 'react'
import ReactDOM from 'react-dom'
import MediaQuery from 'react-responsive'

export default class HomepageVideoModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hero_video_playing: false
    }
  }

  componentDidMount() {
    this.setScrollerWidth()
    window.addEventListener('resize', this.setScrollerWidth.bind(this))
  }

  setScrollerWidth() {
    let articlesList = ReactDOM.findDOMNode(this).querySelector('.articles-list')

    if (window.innerWidth < 769) {
      let articles = articlesList.querySelectorAll('.article')
      let articlesListWidth = articles[0].clientWidth * articles.length + 40
      articlesList.style.width = articlesListWidth + 'px'
    } else {
      articlesList.style.width = ''
    }
  }

  getHeroArticle() {
    return this.getArticleMarkup(this.props.articles[0], 'hero')
  }

  getArticles() {
    return this.props.articles.slice(1).map(this.getArticleMarkup)
  }

  playHeroVideo(e) {
    e.preventDefault()
    this.setState({
      hero_video_playing: true
    })
    window.dataLayer.push({
      event: 'heroVideoClick'
    });
  }

  getVideoEmbedCode(article) {
    let video = article.media.video
    if (video.type === 'vimeo') {
      return `//player.vimeo.com/video/${video.id}?color=ffffff&byline=0&portrait=0&autoplay=1`
    } else if (video.type === 'youtube') {
      return `//youtube.com/embed/${video.id}?autoplay=1`
    } else if (video.type === 'brightcove') {
      return `//players.brightcove.net/6058082952001/${__H_CONFIG__.brightcovePlayerId}/index.html?videoId=${video.id}`
    }
  }

  getArticleMarkup(article, key) {
    let classes = ['article']
    let is_hero = false
    if (key === 'hero') {
      is_hero = true
      classes.push('article--hero')
    }

    const ix = new ImgixHelper(article.media.image.url)

    return (
      <div className={classes.join(' ')} key={key}>
        <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
          {is_hero && (
            <div className="play-button">
              <svg className="icon icon-play">
                <use xlinkHref="#icon-play" />
              </svg>
            </div>
          )}
        </div>
        <h3 className="article-title leading-tight">
          <span>{article.metadata.column_name}</span>
          {article.message.title}
        </h3>
        <MediaQuery query={`(max-width: 1088px)`}>
          <a href={article.action.share_url} className="article-hero-image__link" />
        </MediaQuery>
        <MediaQuery query={`(min-width: 1088px)`}>
          <div>
            {(() => {
              if (is_hero && !this.state.hero_video_playing) {
                return <a href="#" onClick={this.playHeroVideo.bind(this)} className="article-hero-image__link" />
              } else if (!is_hero) {
                return <a href={article.action.share_url} className="article-hero-image__link" />
              }
            })()}
          </div>
        </MediaQuery>

        <MediaQuery query={`(min-width: 1088px)`}>
          <div>
            {is_hero && this.state.hero_video_playing && (
              <iframe
                src={this.getVideoEmbedCode.call(this, article)}
                frameBorder="0"
                allowFullScreen
                className="article--hero__video-embed"
              />
            )}
          </div>
        </MediaQuery>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.getHeroArticle()}
        <div className="articles-scroller">
          <div className="articles-list">{this.getArticles()}</div>
        </div>
        <div className="articles-scroller-mask" />
      </div>
    )
  }
}
