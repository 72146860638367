/*
 ** GAEventTracking Module
 */
var GAEventTracking = (function(document, window, undefined) {
  var trackEvent = function(category, action, label) {
    ga('send', 'event', category, action, label)
  }

  var trackPageview = function(page) {
    ga('send', 'pageview', page)
  }

  return {
    trackEvent: trackEvent,
    trackPageview: trackPageview
  }
})(document, window)

export default GAEventTracking
