import React from 'react'
import $ from 'jquery'
import { nt } from 'helpers/i18n'
const i18n = nt('home.index.newsletter')

export default class HomepageNewsletterModule extends React.Component {
  constructor(props) {
    super(props)

    this.$form = React.createRef()
    this.state = {
      form_result: '',
      loading: false
    }
  }

  onFormSubmit(e) {
    e.preventDefault()
    this.setState({
      loading: true
    })

    const $form = $(this.$form.current)
    $.ajax({
      url: process.env.MAILCHIMP_NEWSLETTER_URL,
      data: $form.serialize(),
      dataType: 'jsonp',
      contentType: 'application/json; charset=utf-8',
      success: function (resp) {
        if (resp.result === 'error') {
          this.setState({
            form_result: i18n('invalid_email'),
            loading: false
          })
        } else {
          this.setState({
            form_result: i18n('success'),
            loading: false
          })
        }
      }.bind(this)
    })
  }

  render() {
    const { title, image_url, first_subhead, second_subhead, alignment } = this.props

    return (
      <div className="homepage-module homepage-module--newsletter" style={{ backgroundImage: `url(${image_url})` }}>
        <div className={`content-wrapper align-${alignment}`}>
          <p className="heading">{title}</p>
          <p className="subhead">{first_subhead}</p>
          <p className="subhead">{second_subhead}</p>
          <form ref={this.$form} action="#" onSubmit={this.onFormSubmit.bind(this)} method="post" noValidate>
            <input
              type="email"
              name="EMAIL"
              placeholder={i18n('placeholder')}
              className="email-input"
              autoComplete="off"
            />
            <div className="form-result">{this.state.form_result}</div>
            <div className="newsletter-options">
              <label>
                <input type="radio" name="group[33]" value="1" defaultChecked={true} /> {i18n('daily')}
              </label>
              <label>
                <input type="radio" name="group[33]" value="2" /> {i18n('weekly')}
              </label>
            </div>
            {!this.state.loading ? (
              <input type="submit" value={i18n('subscribe')} className="form-submit" />
            ) : (
              <div className="form-loader">
                <p className="loader loader--thin" />
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }
}
