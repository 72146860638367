import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isFunction from 'lodash/isFunction'
import { refreshAds, displayAds } from '../helpers/GoogleTagHelper'

export default class AdContainer extends Component {
  static propTypes = {
    adMappings: PropTypes.array,
    onWindowResize: PropTypes.func,
    onSlotRenderEnded: PropTypes.func,
    render: PropTypes.func.isRequired
  }

  static defaultProps = {
    adMappings: [],
    onSlotRenderEnded: function() {},
    onWindowResize: function() {}
  }

  ads = {}
  windowSize = null

  constructor(props) {
    super(props)

    window.adMappings = window.adMappings || {}
    googletag.cmd.push(() => {
      this.buildAdMappings(props.adMappings)
    })
  }

  buildAdMappings = adMappings => {
    adMappings.forEach(mapping => {
      if (!isFunction(mapping.fn)) return
      const ad = mapping.fn(googletag)
      window.adMappings[mapping.name] = ad.build()
    })
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      displayAds(this.ads, this.props.onSlotRenderEnded)
      this.setWindowSize()
    })

    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  setWindowSize() {
    this.windowSize = window.innerWidth < 1024 ? 'small' : 'large'
  }

  onWindowResize = () => {
    let prevWindowSize = this.windowSize
    this.setWindowSize()

    this.props.onWindowResize(this.windowSize, prevWindowSize)

    if (this.windowSize === 'small' && prevWindowSize === 'large') {
      refreshAds()
    } else if (this.windowSize === 'large' && prevWindowSize === 'small') {
      refreshAds()
    }
  }

  onAdReady = (id, adSlot) => {
    this.ads[id] = adSlot
  }

  render() {
    return <React.Fragment>{this.props.render(this.onAdReady)}</React.Fragment>
  }
}
