import Advertisement from '../Advertisement'
import ImgixHelper from 'helpers/ImgixHelper'
import React from 'react'
import ReactDOM from 'react-dom'
import i18n from 'helpers/i18n'

export default class HomepageTopDiscussionsModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articles: []
    }
  }

  async componentDidMount() {
    const resp = await fetch('/api/v1/collections/top_discussions')
    if (resp.status === 200) {
      const json = await resp.json()
      this.setState({ articles: json.collection.cards })
    }
  }

  componentDidUpdate() {
    imgix.fluid(ReactDOM.findDOMNode(this))
  }

  getArticles() {
    return this.state.articles.map(function(article, i) {
      const ix = new ImgixHelper(article.media.image.url)
      return (
        <li key={i}>
          <a href={article.action.share_url} className="article-link">
            <div className="article-hero-image">
              <div className="imgix-fluid imgix-fluid-bg" data-src={ix.getURL()} />
            </div>

            <p className="article-title">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </p>
          </a>
        </li>
      )
    })
  }

  render() {
    let { onAdReady, ad_targeting, type } = this.props

    return (
      <div className="homepage-module homepage-module--top-discussions">
        <div className="content-wrapper">
          <h2>
            <span>{i18n('home.index.most_discussed')}</span>
          </h2>
          <Advertisement
            onAdReady={onAdReady}
            parent_component={type}
            customTargeting={ad_targeting}
            type="homepageTallAd"
            ad_id="tall"
          />
          <ol className="articles-list">{this.getArticles()}</ol>
        </div>
      </div>
    )
  }
}
