import React, { useState, useEffect, useRef } from 'react'
import SunCalc from 'suncalc'
import moment from 'moment'

import useOutsideClickEvent from 'site/hooks/useOutsideClickEvent'
import Moonphase from './Moonphase'

import leap1 from './moonphase/leap-1.svg'
import leap2 from './moonphase/leap-2.svg'
import leap3 from './moonphase/leap-3.svg'
import leap0 from './moonphase/leap-l.svg'

import { nt } from 'helpers/i18n'
const i18n = nt('moonphase')

const phaseToName = phase => {
  if (phase < 0.02 || phase >= 0.98) return i18n('new_moon')
  if (phase >= 0.02 && phase < 0.245) return i18n('waxing_crescent')
  if (phase >= 0.245 && phase < 0.255) return i18n('first_quarter')
  if (phase >= 0.255 && phase < 0.45) return i18n('waxing_gibbous')
  if (phase >= 0.45 && phase < 0.55) return i18n('full_moon')
  if (phase >= 0.55 && phase < 0.7) return i18n('waning_gibbous')
  if (phase >= 0.7 && phase < 0.8) return i18n('last_quarter')
  if (phase >= 0.8 && phase < 0.98) return i18n('waning_crescent')
}

export default function SiteHeaderMoonphase() {
  const [open, setOpen] = useState(false)
  const [phase, setPhase] = useState(null)
  const [utcTime, setUtcTime] = useState(moment.utc().format('HH:mm'))

  const wrapperRef = useRef(null)
  useOutsideClickEvent(wrapperRef, () => setOpen(false))

  const updatePhase = () => setPhase(SunCalc.getMoonIllumination().phase)

  let timer
  useEffect(() => {
    updatePhase()
    timer = setTimeout(() => {
      setUtcTime(moment.utc().format('HH:mm'))
      updatePhase()
    }, 10000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const date = new Date()
  const leapYear = date.getFullYear() + (4 - ((date.getFullYear() - 2016) % 4))
  const leapYearProgress = (date.getFullYear() - 2016) % 4
  const leapIndicator = [leap0, leap1, leap2, leap3][leapYearProgress]

  return (
    <div className="header-moonphase" ref={wrapperRef}>
      <div className="moonphase-small" onClick={e => setOpen(!open)} onKeyPress={e => setOpen(!open)} tabIndex="0">
        <Moonphase moonphaseSize="small" phase={phase} />
      </div>

      {open && (
        <div className="moonphase-popover">
          <p className="moonphase-name">{phaseToName(phase)}</p>
          <div className="moonphase-large">
            <Moonphase moonphaseSize="large" phase={phase} />
          </div>
          <table className="extra-info">
            <tbody>
              <tr>
                <td>
                  <p className="extra-info-value">{leapYear}</p>
                  <p className="extra-info-description">{i18n('leap_year')}</p>
                </td>
                <td>
                  <img src={leapIndicator} />
                </td>
                <td>
                  <p className="extra-info-value">{utcTime}</p>
                  <p className="extra-info-description">{i18n('utc')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
