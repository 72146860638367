import React from 'react'
import ReactDOM from 'react-dom'
import Hammer from 'hammerjs/hammer'
import ImgixHelper from 'helpers/ImgixHelper'
import i18n from 'helpers/i18n'

export default class HomepageEditorsPicksModule extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      collection: {},
      active_index: 0,
      margin: 150
    }
  }

  setModuleMinHeight() {
    const articleTitles = ReactDOM.findDOMNode(this).querySelectorAll('.article-title')
    let tallestTitleHeight = 0
    articleTitles.forEach(title => {
      if (title.clientHeight > tallestTitleHeight) {
        tallestTitleHeight = title.clientHeight
      }
    })

    this.setState({
      margin: 55 + Math.ceil(tallestTitleHeight / 45) * 45
    })
  }

  onArrowClick(direction, e) {
    e.preventDefault()
    clearInterval(this.autoAdvanceInterval)
    this.changeSlide(direction)
  }

  changeSlide(direction) {
    if (direction === 'next') {
      if (this.state.active_index + 1 === this.articles().length) {
        this.setActiveSlide(0)
      } else {
        this.setActiveSlide(Math.min(this.state.active_index + 1, this.articles().length - 1))
      }
    } else {
      if (this.state.active_index === 0) {
        this.setActiveSlide(this.articles().length - 1)
      } else {
        this.setActiveSlide(Math.max(this.state.active_index - 1, 0))
      }
    }
  }

  componentDidMount() {
    this.fetchArticles()

    let ham = new Hammer(ReactDOM.findDOMNode(this).querySelector('.articles'))
    ham.on('swipeleft swiperight', e => {
      switch (e.type) {
        case 'swipeleft':
          this.changeSlide('next')
          break
        case 'swiperight':
          this.changeSlide('prev')
          break
        default:
          break
      }
    })

    window.addEventListener('resize', () => {
      if (window.innerWidth < 769) {
        this.setModuleMinHeight()
      } else {
        this.setState({
          margin: 150
        })
      }
    })

    this.autoAdvanceInterval = setInterval(() => {
      this.setState((prevState, props) => {
        if (prevState.active_index + 1 < this.articles().length) {
          prevState.active_index++
        } else {
          prevState.active_index = 0
        }

        return prevState
      })
    }, 5000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.collection.id && this.state.collection.id) {
      this.setModuleMinHeight()
      imgix.fluid(ReactDOM.findDOMNode(this))
    }
  }

  async fetchArticles() {
    const resp = await fetch('/api/v1/collections/editors_picks')
    if (resp.status === 200) {
      const data = await resp.json()
      this.setState({ collection: data.collection })
    }
  }

  articles() {
    return this.state.collection.cards || []
  }

  setActiveSlide(index, e) {
    if (e) e.preventDefault()
    this.setState({
      active_index: index
    })
  }

  getArticles() {
    return this.articles().map((article, i) => {
      let classes = ['article']
      if (this.state.active_index === i) {
        classes.push('visible')
      }

      const ix = new ImgixHelper(article.media.image.url)

      return (
        <div className={classes.join(' ')} key={i}>
          <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()} />
          <h3 className="article-title">
            <span>{article.metadata.column_name}</span>
            {article.message.title}
          </h3>
          <a href={article.action.share_url} className="article-hero-image__link" />
        </div>
      )
    })
  }

  getPagination() {
    let articles_count = this.articles().length
    return this.articles().map((article, i) => {
      let classes = ['article-select']
      if (this.state.active_index === i) {
        classes.push('active')
      }
      return (
        <a href="#" onClick={this.setActiveSlide.bind(this, i)} key={i} className={classes.join(' ')}>
          <i />
        </a>
      )
    })
  }

  render() {
    return (
      <div>
        <div className="homepage-module homepage-module--editors-picks">
          <h2>
            <span>{i18n('home.index.editors_picks')}</span>
          </h2>
          <div className="articles" style={{ marginBottom: this.state.margin }}>
            {this.getArticles()}
          </div>
          <div className="pagination-dots">{this.getPagination()}</div>
          <div className="arrows">
            <a href="#" onClick={this.onArrowClick.bind(this, 'prev')} className="arrow-button arrow-button--prev">
              <svg className="icon-arrow arrow-prev">
                <use xlinkHref="#icon-right-black" />
              </svg>
            </a>
            <a href="#" onClick={this.onArrowClick.bind(this, 'next')} className="arrow-button arrow-button--next">
              <svg className="icon-arrow arrow-next">
                <use xlinkHref="#icon-right-black" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
