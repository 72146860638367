import React from 'react'

import HomepageAppDownloadModule from './HomepageAppDownloadModule'
import HomepageInstagramFollowModule from './HomepageInstagramFollowModule'

export default function HomepageMarketingModules({ app, instagram, app_link, instagram_handle }) {
  if (app == '0' && instagram == '0') return null

  return (
    <div className="homepage-module homepage-module--marketing">
      <div className="content-wrapper">
        <hr className="grey-divider" />
        <div className="modules-container">
          {app == '1' && <HomepageAppDownloadModule link={app_link} />}
          {instagram == '1' && <HomepageInstagramFollowModule handle={instagram_handle} />}
        </div>
      </div>
    </div>
  )
}
