import GAEventTracking from './modules/GAEventTracking'

/*
** AdManager
** Inspired by https://support.google.com/dfp_premium/answer/4578089?hl=en
**  my mama bob
*/
var AdManager = ( function( GAEventTracking, document, window, undefined ) {

    var adManager = null;

    function newAdManager() {

        var adSlots = __H_CONFIG__.adSlots,
        adMappings = {},
        currentAdSlots = [],
        newAdSlots = [],
        firedScreenSizes = [],
        loadMore = false;

        function init() {
            // Force refresh if a slot is empty: it fixes a limitation set by Google DFP if a user go
            // back/forward quickly using browser's buttons (within 30 secs), the ad doesn't get refreshed
            googletag.cmd.push(function() {
                // Check if a content blocker has prevented DFP JS from loading.
                // If so, googletag.defineSlot will be undefined, so no use in running the rest of the script,
                // just make sure the sidebar get shown.
                if (typeof window.googletag.defineSlot === 'undefined') {
                    _displayAds();
                    return;
                }

                // Define ad slots available sizes
                _defineAdMappings();

                // Init ad slots
                initAllAdSlots();

                // Disable initial load, we will use refresh() to fetch ads.
                // Calling this function means that display() just registers
                // the slot as ready, but does not fetch ads for it.
                googletag.pubads().disableInitialLoad();

                // Ensure ads are fetched in a single request, which is a bit more performant
                // and allows for DFP roadblocks.
                googletag.pubads().enableSingleRequest();

                googletag.pubads().enableLazyLoad({
                  fetchMarginPercent: -1,   // Fetch ads immediately
                  renderMarginPercent: 100, // Render slots within 1 viewports.
                  mobileScaling: 2.0        // Double the above values on mobile.
                });

                googletag.enableServices();

                _displayAds();

                /**
                 * Callback executed when a slot is finished rendering.
                 * Since the fullbleed isn't always utilized, and it has the ADVERTISEMENT label in its parent DIV,
                 * this logic will check that parent DIV to see if it should be hidden if the slot comes back empty.
                 */
                googletag.pubads().addEventListener('slotRenderEnded', function(event) {
                    if (event.isEmpty) {
                        var slotElementId = event.slot.getSlotElementId();
                        var slotElement = document.getElementById(slotElementId);
                        var parentElement = slotElement.parentNode;
                        if (parentElement.className.indexOf('hide-on-empty') !== -1) {
                            parentElement.style.display = 'none';
                        }
                    }
                });
            });

            //console.log('[AdManager] Fetching ads on', 'section');
        }

        // Callback called in router.js when new content is appended
        function afterContentChange() {
            newAdSlots = [];

            // Changes the correlator that is sent with ad requests, effectively starting a new page view
            _updateCorrelator();

            // console.log('[AdManager] Fetching ads on', 'section');

            initAllAdSlots();

            _displayAds();
        }

        function _updateCorrelator() {
            googletag.cmd.push(function() {
                // https://developers.google.com/doubleclick-gpt/reference?rd=1#googletag.PubAdsService_updateCorrelator
                googletag.pubads().updateCorrelator();
                //console.log('[AdManager] Correlator updated!');
            });
        }

        // Initialize a new ad slot
        function _initAdSlot(type, id, customTargeting) {
            var adSlotSettings = adSlots[type];
            if( adSlotSettings ) {
                googletag.cmd.push(function() {
                    if (currentAdSlots[id]) {
                        return;
                    } else {
                        var adSlot = googletag.defineSlot(adSlotSettings.google_id, adSlotSettings.sizes, id);
                    }

                    // Set ad mapping
                    if( adSlotSettings.mapping ) {
                        console.log(`[AdManager] Adding ${adSlotSettings.mapping} mapping to ${id}`)
                        adSlot = adSlot.defineSizeMapping(adMappings[adSlotSettings.mapping]);
                    }

                    const foldLabel = _foldLabel(id, adSlotSettings.sizes[0]);
                    console.log(`[AdManager] ${id} is ${foldLabel}`)
                    adSlot = adSlot.setTargeting('loc', foldLabel)

                    // Set custom criterias
                    if( customTargeting ) {
                        customTargeting = JSON.parse(customTargeting);
                        for (var i = 0; i < customTargeting.length; i++) {
                            for(var key in customTargeting[i]) {
                                if( customTargeting[i].hasOwnProperty( key ) ) {
                                    console.log('[AdManager] Custom Targeting set to:', key + '=' + customTargeting[i][key]);
                                    adSlot = adSlot.setTargeting(key, customTargeting[i][key]);
                                }
                            }
                        }
                    }

                    adSlot.setCollapseEmptyDiv(true).addService(googletag.pubads());

                    // Store ad slot object into cache array
                    currentAdSlots[id] = adSlot;
                    newAdSlots[id] = adSlot;

                    console.log('[AdManager] Ad displayed :', id);
                });
            }
            else {
                console.error('[AdManager] Failed to initialize ad slot!', type);
            }
        }

        // Initialize all ad slots
        function initAdSlots(divs) {
            divs.forEach(function(el) {
                _initAdSlot(el.dataset.adType, el.id, el.dataset.adTargeting);
            });
        }

        function _foldLabel(slotId, slotSize) {
            const slot = document.getElementById(slotId);
            const viewportHeight = document.documentElement.clientHeight;
            const slotPosition = _offset(slot)
            const slotHeight = slotSize[1]

            return slotPosition.top + (slotHeight / 2) < viewportHeight ? 'atf' : 'btf';
        }

        function _offset(el) {
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function _displayAds() {
            // Display only the new ads that have been inserted into the page
            var adIds = Object.keys(newAdSlots);
            var adsToDisplay = [];

            for (var i = 0; i < adIds.length; i++) {
                adsToDisplay.push(currentAdSlots[adIds[i]]);
                googletag.cmd.push(function() {
                    googletag.display(adIds[i]);
                });
            }

            setLoadMore(false);

            // Since disableInitialLoad has been called, the slots need to be loaded manually via refresh().
            // This works in tandem with single-request mode and inifinite scroll so that, when > 1 ad is
            // inserted into the page, it only sends a single request to DFP.
            googletag.cmd.push(function() {
                googletag.pubads().refresh(adsToDisplay);
            });
        }

        function initAllAdSlots() {
            var adSlots = document.querySelectorAll('.google-ad')
            initAdSlots(adSlots);
        }

        // Define all ad size mappings
        function _defineAdMappings() {
            ////console.log('[AdManager] Defining ad slots size mappings');

            googletag.cmd.push(function() {
                /* Ads Mappings */
                adMappings['homepageFullbleedAdMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], [320, 420]) // mobile
                    .addSize([768, 200], [768, 720]) // tablet
                    .addSize([1440, 200], [1440, 720]) // desktop
                    .build();

                adMappings['billboardAdMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], [300, 250]) // mobile
                    .addSize([1024, 200], [970, 250]) // tablet and above
                    .build();

                adMappings['billboardAdLargeScreensMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], []) // mobile
                    .addSize([1024, 200], [970, 250]) // tablet and above
                    .build();

                adMappings['headerAdMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], [320, 50]) // mobile and tablet
                    .addSize([768, 200], [728, 90]) // tablet and above
                    .build();

                adMappings['headerAdSansMobileMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], []) // mobile
                    .addSize([768, 200], [728, 90]) // tablet and above
                    .build();

                adMappings['articleTallAdMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], []) // mobile
                    .addSize([768, 200], [[300, 601], [300, 600]]) // tablet and above
                    .build();

                adMappings['videoAdMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], [300, 250])
                    .build();

                adMappings['articleSquareMobileOnlyMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], [300, 250]) // mobile only
                    .addSize([1025, 200], []) // tablet and above
                    .build();

                adMappings['interscrollerMobileOnlyMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], [300, 250]) // mobile only
                    .addSize([1025, 200], []) // tablet and above
                    .build();

                adMappings['articleBreakerAdMapping'] = googletag.sizeMapping()
                    .addSize([0, 0], [300,250]) // mobile
                    .addSize([768, 200], [728, 90]) // tablet and above
                    .build();
                
                adMappings['mappingMidLeaderboardsArticle'] = googletag.sizeMapping()
                    .addSize(
                      [1024, 200],
                      [
                        [970, 250],
                        [1, 1]
                      ]
                    )
                    .addSize(
                      [0, 0],
                      [
                        [481, 250],
                        [300, 250],
                        [1, 1]
                      ]
                    )
                    .build()
            });
        }

        function setLoadMore(newValue) {
            loadMore = newValue;
        }

        return {
            init: init,
            afterContentChange: afterContentChange,
            initAdSlots: initAdSlots,
            initAllAdSlots: initAllAdSlots,
            setLoadMore: setLoadMore
        };

    }

    function getAdManager() {
        if( ! adManager ) {
            adManager = new newAdManager();
        }
        return adManager;
    }

    return {
        getAdManager : getAdManager
    };

})( GAEventTracking, document, window );

export default AdManager
