import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import adSlotConfig from '../helpers/AdSlotConfig'
import { isElementInView, guid } from '../helpers'
import i18n from 'helpers/i18n'

export default class Advertisement extends PureComponent {
  static propTypes = {
    hidden: PropTypes.bool,
    ad_id: PropTypes.string.isRequired,
    customTargeting: PropTypes.array
  }

  static defaultProps = {
    customTargeting: []
  }

  viewabilityTracked = false
  $ad = null

  constructor(props) {
    super(props)

    this.state = {
      id: `${this.props.ad_id}-${guid()}`
    }
  }

  isAdInView() {
    return isElementInView(this.$ad)
  }

  foldLabel(slotSize) {
    const viewportHeight = document.documentElement.clientHeight
    const slotPosition = this.adOffset()
    const slotHeight = slotSize[1]

    return slotPosition.top + slotHeight / 2 < viewportHeight ? 'atf' : 'btf'
  }

  adOffset() {
    const rect = this.$ad.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  trackViewability = () => {
    if (this.isAdInView() && !this.viewabilityTimer && !this.viewabilityTracked) {
      this.viewabilityTimer = setTimeout(() => {
        this.viewabilityTimer = undefined
        if (this.isAdInView()) {
          this.viewabilityTracked = true
          const label = this.props.parent_component
            ? `${this.props.parent_component}-${this.props.ad_id}`
            : `${this.props.ad_id}`
          window.dataLayer.push({
            event: 'ad-viewability',
            adViewable: label
          });
        }
      }, 1000)
    }
  }

  setCustomTargeting(customTargeting) {
    let adSlot = this.adSlot

    for (var i = 0; i < customTargeting.length; i++) {
      for (var key in customTargeting[i]) {
        if (customTargeting[i].hasOwnProperty(key)) {
          adSlot = adSlot.setTargeting(key, customTargeting[i][key])
        }
      }
    }

    return adSlot
  }

  componentDidMount() {
    let { type, customTargeting } = this.props
    var adSlotSettings = adSlotConfig[type]

    if (adSlotSettings) {
      googletag.cmd.push(() => {
        this.adSlot = googletag.defineSlot(adSlotSettings.google_id, adSlotSettings.sizes, this.state.id)

        // Set ad mapping
        if (adSlotSettings.mapping) {
          this.adSlot = this.adSlot.defineSizeMapping(window.adMappings[adSlotSettings.mapping])
        }

        const foldLabel = this.foldLabel(adSlotSettings.sizes[0])
        this.adSlot = this.adSlot.setTargeting('loc', foldLabel)

        // Set custom criterias
        if (customTargeting) {
          this.adSlot = this.setCustomTargeting(customTargeting)
        }

        this.adSlot.setCollapseEmptyDiv(true).addService(googletag.pubads())

        if (this.props.onAdReady) {
          this.props.onAdReady(this.state.id, this.adSlot)
        }

        googletag.pubads().addEventListener('slotRenderEnded', event => {
          if (!event.isEmpty && event.slot.getSlotElementId() === this.state.id) {
            this.trackViewability()
            window.addEventListener('scroll', this.trackViewability)
          }
        })
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackViewability)
  }

  render() {
    const { id } = this.state
    const { ad_id, hidden } = this.props

    return (
      <div
        className={`ad google-ad ad--${ad_id}`}
        ref={el => (this.$ad = el)}
        style={{ position: hidden ? 'absolute' : 'static', left: hidden ? '-9999px' : 'auto' }}
      >
        <div className="ad-title">{i18n('blocks.advertisement.advertisement')}</div>
        <div id={id} />
      </div>
    )
  }
}
