import React from 'react'
import { nt } from 'helpers/i18n'
const i18n = nt('home.index.marketing')
const ariaI18n = nt('home.accessibility')

export default function HomepageInstagramFollowModule({ handle }) {
  const ariaLabel = `${i18n('follow_us_on')} Instagram, ${ariaI18n('new_window')}`

  return (
    <div className="marketing-module">
      <div className="marketing-module__ig-teaser-image" />
      <h3>
        <span className="header-pre">{i18n('follow_us_on')}</span>Instagram
      </h3>
      <p>{i18n('instagram_desc')}</p>
      <a className="cta-text" href={`http://www.instagram.com/${handle}`} target="_blank" aria-label={ariaLabel}>
        {i18n('follow_us')}
      </a>
    </div>
  )
}
