import React from 'react'
import ReactDOM from 'react-dom'
import Advertisement from '../Advertisement'
import Authors from './Authors'
import ImgixHelper from 'helpers/ImgixHelper'
import ArticleBadge from './ArticleBadge'
import i18n from 'helpers/i18n'

export default class HomepageLatestModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articles: []
    }
  }

  async componentDidMount() {
    const resp = await fetch('/api/v1/homepage/latest')
    if (resp.status === 200) {
      this.setState({ articles: (await resp.json()).cards })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.articles.length > 0) {
      imgix.fluid(ReactDOM.findDOMNode(this))
    }
  }

  render() {
    if (!this.state.articles.length) return null
    let { onAdReady, ad_targeting, type } = this.props

    return (
      <div className="homepage-module homepage-module--latest">
        <div className="content-wrapper small-fullbleed">
          <h2>
            <span>{i18n('home.index.latest')}</span>
          </h2>
          <div className="articles-grid">
            <Advertisement
              onAdReady={onAdReady}
              parent_component={type}
              customTargeting={ad_targeting}
              type="homepageTallAd"
              ad_id="tall"
            />
            {this.state.articles.map((article, i) => (
              <Article key={article.id} index={i} article={article} onAdReady={onAdReady} />
            ))}
          </div>
          <a href="/latest" className="btn">
            {i18n('home.index.view_all')}
          </a>
        </div>
      </div>
    )
  }
}

const Article = ({ article, onAdReady, index }) => {
  let classes = 'article'
  let article_hero_image

  if (index === 0) {
    classes += ' article--hero'
    let article_hero_url = new ImgixHelper(article.media.image.url, {
      blend: '#f2f2f2',
      bm: 'multiply'
    })

    article_hero_image = article_hero_url.getURL()
  } else {
    article_hero_image = new ImgixHelper(article.media.image.url).getURL()
  }

  return (
    <div className={classes}>
      <a href={article.action.share_url} className="block">
        <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={article_hero_image}>
          <ArticleBadge article={article} className="article__badge" />
        </div>

        <div className="article-info">
          <h3 className="article-title">
            <span>{article.metadata.column_name}</span>
            {article.message.title}
          </h3>
        </div>

        <div className="article-sub-info">
          <p className="article-lede" dangerouslySetInnerHTML={{ __html: article.message.text_html }} />
          <p className="article-meta">
            <Authors authors={article.authors} />
          </p>
        </div>
      </a>

      {index === 2 && <Advertisement onAdReady={onAdReady} type="homepageSquareAd" ad_id="square" />}
    </div>
  )
}
