import imgix from 'imgix.js'

// Factory helper method
export function withImgix(src, opts = { fm: null }, clampDPR = 0) {
  if (Array.isArray(src)) {
    return src.map(s => withImgix(s, opts, clampDPR))
  }

  const ix = new ImgixHelper(src, opts, clampDPR)
  return ix.getURL()
}

export function generateSrcSet(src, sizes = {}, opts = {}) {
  return Object.keys(sizes)
    .map(key => {
      return `${withImgix(src, Object.assign({}, opts, sizes[key]))} ${key}`
    })
    .join(', ')
}

export default class ImgixHelper {
  static placeholder(text, opts = {}) {
    let ix = new imgix.URL(`https://${__H_CONFIG__.imgixHost}/~text`)
    let options = Object.assign(
      {},
      {
        txtsize: 33,
        txtalign: 'middle,center',
        txt: text,
        txtclr: '333333',
        bg: 'E8E8E8',
        dpr: window.devicePixelRatio
      },
      opts
    )

    ix.setParams(options)

    return ix.getURL()
  }

  static avatarUrl(member, opts = {}) {
    const avatarUrl = member.avatar_url || member.avatarUrl
    let url
    if (avatarUrl) {
      let ix = new ImgixHelper(avatarUrl, Object.assign({ crop: 'faces', mask: 'ellipse', fm: 'png' }, opts))
      url = ix.getURL()
    } else {
      const displayName = member.display_name || member.displayName
      url = ImgixHelper.placeholder(
        displayName[0].toUpperCase(),
        Object.assign(
          {
            txtsize: 14,
            txtalign: 'middle,center',
            txtclr: 'FFFFFF',
            txtfit: 'max',
            txtpad: 0,
            bg: '999999',
            mask: 'ellipse',
            ch: 'Width,DPR,Save-Data',
            fm: 'png'
          },
          opts
        )
      )
    }

    return url
  }

  constructor(url, options = {}, clampDPR = 0) {
    this.url = url

    let dpr
    const { devicePixelRatio } = window

    if (clampDPR > 0 && devicePixelRatio > clampDPR) {
      dpr = clampDPR
    } else {
      dpr = devicePixelRatio
    }

    this.options = Object.assign(
      {},
      {
        ch: 'Width,DPR,Save-Data',
        dpr: dpr,
        fit: 'crop',
        fm: 'jpg',
        q: 55,
        auto: 'format',
        usm: 12
      },
      options
    )
  }

  /**
   * Load an image and return its dimensions. Helpful for determining aspect image ratio.
   * Depends on jQuery's Deferred.
   */
  static getImageDimensions(imageSrc) {
    return new Promise(resolve => {
      let img = new Image()

      img.src = imageSrc

      img.onload = function() {
        let height = img.height
        let width = img.width

        resolve({
          width: width,
          height: height
        })
      }
    })
  }

  get imageUrl() {
    const url = document.createElement('a')
    url.href = this.url

    // Already an Imgix image, return URL as-is
    if (url.hostname.match(/imgix/)) {
      return this.url
    }

    const imgixEndpoint = __H_CONFIG__.imgixMapping[url.hostname]
    url.protocol = 'https:'
    if (imgixEndpoint) {
      // We have a configured Imgix mapping, swap the hostname
      url.hostname = imgixEndpoint
      return url.toString()
    } else if (url.hostname.match(/shopify/)) {
      // Shopify image
      url.hostname = 'hodinkee-shopify.imgix.net'
      return url.toString()
    } else {
      // Dunno, return original
      return this.url
    }
  }

  getURL() {
    const ix = new imgix.URL(this.imageUrl)
    ix.setParams(this.options)

    return ix.getURL()
  }
}

window.ImgixHelper = ImgixHelper
