import React from 'react'
import HomepagePopularBrand from './HomepagePopularBrand'
import i18n from 'helpers/i18n'

export default class HomepagePopularBrandsModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      brands: []
    }
  }

  componentDidMount() {
    this.loadBrands()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brand_ids != this.props.brand_ids) {
      this.loadBrands()
    }
  }

  async loadBrands() {
    if (!this.props.brand_ids || this.props.brand_ids.length === 0) return
    const params = this.props.brand_ids.map(id => `ids[]=${id}`).join('&')

    try {
      const resp = await fetch(`/api/v1/brands?${params}`)
      if (resp.status === 200) {
        const json = await resp.json()
        this.setState({ brands: json.brands })
      }
    } catch (e) {
      // TODO - empty state
    }
  }

  getBrands() {
    return this.state.brands.map(brand => {
      return <HomepagePopularBrand key={brand.id} brand={brand} />
    })
  }

  render() {
    return (
      <div className="homepage-module homepage-module--popular-brands">
        <div className="content-wrapper">
          <p className="title">{i18n('home.index.popular_brands')}</p>
          <div className="popular-brands-container">{this.getBrands()}</div>
        </div>
      </div>
    )
  }
}
