import React from 'react'
import HomepageFlexModule from './HomepageFlexModule'
import i18n from 'helpers/i18n'

export default class HomepagePackageModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      package: {},
      disabled: false
    }
  }

  async componentDidMount() {
    if (!this.props.package_id) return
    const resp = await fetch(`/api/v1/packages/${this.props.package_id}`)
    if (resp.status === 200) {
      const data = await resp.json()
      this.setState({ package: data.package })
    }
  }

  articles() {
    return (this.state.package.cards || []).slice(0, 4)
  }

  render() {
    if (this.state.disabled || !this.articles().length) return <div />

    // package is a reserved word in JS, of course
    const pack = this.state.package

    return (
      <div className="homepage-module homepage-module--flex">
        <div className="content-wrapper mobile-fullbleed">
          <h2>
            <span>{pack.title}</span>
          </h2>
          <HomepageFlexModule type="package" {...this.props} articles={this.articles()} />
          <a href={`/packages/${pack.slug}`} className="btn">
            {i18n('home.index.view_all')}
          </a>
        </div>
      </div>
    )
  }
}
