import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'

import Homepage from './components/Homepage'
import SiteHeaderMoonphase from './components/SiteHeaderMoonphase'
// import NewsletterModal from './components/NewsletterModal.js'

import TallFallback from './fallbacks/TallFallback'
import ShortFallback from './fallbacks/ShortFallback'
import FullscreenFallback from './fallbacks/FullscreenFallback'
const Browse = lazyComponent(() => import('./components/Browse'), <TallFallback />)
const Comments = lazyComponent(() => import('./components/Comments'), <ShortFallback />)
const CommentCount = lazyComponent(() => import('./components/CommentCount'), <span />)
const HomepageNewsletterModule = lazyComponent(
  () => import('./components/homepage_modules/HomepageNewsletterModule'),
  <div />
)
const PodcastHomepageDropdowns = lazyComponent(() => import('./components/podcast/PodcastHomepageDropdowns'), <div />)
const PodcastFooter = lazyComponent(() => import('./components/podcast/PodcastFooter'), <div />)
const Search = lazyComponent(() => import('./components/Search'), <TallFallback />)
const PackageSeries = lazyComponent(() => import('./components/PackageSeries'), <div />)
const PollContentBlock = lazyComponent(() => import('./components/PollContentBlock'), <ShortFallback />)
const PackageContainer = lazyComponent(() => import('./components/packages/PackageContainer'), <FullscreenFallback />)
const Masthead = lazyComponent(() => import('./components/masthead/Masthead'), <TallFallback />)
const HodinkeeTen = lazyComponent(() => import('./components/hodinkee_ten/HodinkeeTen'))
const ProductCard = lazyComponent(() => import('./components/ProductCard/index'))

WebpackerReact.setup({
  Browse,
  Comments,
  CommentCount,
  Homepage,
  HomepageNewsletterModule,
  PodcastHomepageDropdowns,
  PollContentBlock,
  PodcastFooter,
  Search,
  SiteHeaderMoonphase,
  PackageSeries,
  PackageContainer,
  Masthead,
  HodinkeeTen,
  ProductCard
})
